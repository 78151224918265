<template>
  <div
    v-if="productDetail && imgsProduct && imgsProduct.imagenes"
    class="container-product-detail-movil"
  >
    <carousel
      :key="colorSelect"
      :dots="true"
      :nav="false"
      :responsive="{ 0: { items: 1, nav: false, stagePadding: 0 } }"
      class="content-carousel-products"
    >
      <div
        v-for="(data, index) in imgsProduct.imagenes"
        :key="index + 'carousel'"
      >
        <img
          class="w-100"
          style="background-color: #f0f5f6; aspect-ratio: 39/44"
          :src="data"
        />
      </div>
    </carousel>
    <div class="container-data-product-movil">
      <div
        v-if="!imgsProduct.tags && productDetail.exclusivo_online == 1"
        class="tags-product"
      >
        <p>Exclusivo online</p>
      </div>
      <div v-if="imgsProduct.tags" class="tags-product">
        <p v-if="productDetail.exclusivo_online == 1">Exclusivo online</p>
        <p
          v-for="(tag, index) in imgsProduct.tags"
          :key="index"
          class="tag-product"
          :style="{
            'background-color': tag.color_fondo,
            color: tag.color_texto,
          }"
        >
          {{ tag.tag }}
        </p>
      </div>
      <div class="d-flex mb-3">
        <h1 class="col pe-4">
          {{
            productDetail.titulo
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase())
          }}
        </h1>
        <div style="width: 50px">
          <btn-add-fav
            :color="colorSelect"
            :product-sku="productDetail.producto_sku"
          />
        </div>
      </div>
      <div v-if="imgsProduct.habilitado" class="container-price-product-detail">
        <del v-if="productDetail.precio_antes > 0"
          >${{
            new Intl.NumberFormat('de-DE').format(productDetail.precio_antes)
          }}</del
        >
        <p>
          ${{ new Intl.NumberFormat('de-DE').format(productDetail.precio) }}
        </p>
      </div>
      <div v-if="imgsProduct.tallas.length > 1" class="box-size pb-3 mb-3">
        <span
          class="guia-de-tallas"
          @click="
            $store.state.products.showSizeProduct = !$store.state.products
              .showSizeProduct
          "
          >Guía de tallas</span
        >
      </div>
      <div v-if="errorAddToCart" class="text-center w-100 my-4">
        <span class="text-error">{{ msgErrorAddToCart }}</span>
      </div>
      <product-not-available v-if="!imgsProduct.habilitado" />
      <div v-b-modal.modalcupo class="container-cuotas-quac">
        <div class="container-logo p-2">
          <img src="@/assets/icons/icon-quac.svg" alt="logo quac" />
        </div>
        <div class="col container-info-cuotas">
          <p>
            Págalo con Quac en hasta
            <b>
              {{ productDetail.cant_cuotas }}
            </b>
            cuotas quincenales.
            <br />
            <span> Más información</span>
          </p>
        </div>
      </div>
      <div
        v-if="showMsgFewUnits"
        style="font-size: 13px;color: red;text-align: center;position: absolute;width: 100%;"
      >
        {{ msgFewUnits }}
      </div>
      <div class="conatiner-acoordeon-movil mt-4">
        <div class="acoordeon-body">
          <div v-b-toggle.collapse-1 class="acoordeon-header">
            <p class="title-header">Descripción</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16.026"
              height="9.425"
              viewBox="0 0 16.026 9.425"
            >
              <g
                id="Icon_ionic-ios-arrow-dropdown"
                data-name="Icon ionic-ios-arrow-dropdown"
                transform="translate(16.026 9.425) rotate(180)"
              >
                <path
                  id="Trazado_6696"
                  data-name="Trazado 6696"
                  d="M.4,9.023A1.362,1.362,0,0,1,.4,7.1L7.124.4A1.356,1.356,0,0,1,8.995.354l6.63,6.609a1.355,1.355,0,1,1-1.912,1.92l-5.7-5.611L2.315,9.03A1.356,1.356,0,0,1,.4,9.023Z"
                  transform="translate(0 0)"
                />
              </g>
            </svg>
          </div>
          <div class="acoordeon-content">
            <b-collapse id="collapse-1">
              <div class="container-info-product-detail">
                <p>
                  {{ productDetail.descripcion }}
                </p>
                <p>
                  SKU {{ productDetail.producto_sku }} <br />
                  <!-- Medidas: {{imgsProduct.modelo}} <br> -->
                  País origen:
                  {{
                    productDetail.origen
                      .toLowerCase()
                      .replace(/^\w/, d => d.toUpperCase())
                  }}
                  - Fabricante / Importador:
                  {{
                    productDetail.importador
                      .toLowerCase()
                      .replace(/^\w/, d => d.toUpperCase())
                  }}
                  <br />
                  Composición: {{ productDetail.composicion }}
                </p>
              </div>
            </b-collapse>
          </div>
        </div>
        <div class="acoordeon-body">
          <div v-b-toggle.collapse-2 class="acoordeon-header">
            <p class="title-header">Materiales y cuidados</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16.026"
              height="9.425"
              viewBox="0 0 16.026 9.425"
            >
              <g
                id="Icon_ionic-ios-arrow-dropdown"
                data-name="Icon ionic-ios-arrow-dropdown"
                transform="translate(16.026 9.425) rotate(180)"
              >
                <path
                  id="Trazado_6696"
                  data-name="Trazado 6696"
                  d="M.4,9.023A1.362,1.362,0,0,1,.4,7.1L7.124.4A1.356,1.356,0,0,1,8.995.354l6.63,6.609a1.355,1.355,0,1,1-1.912,1.92l-5.7-5.611L2.315,9.03A1.356,1.356,0,0,1,.4,9.023Z"
                  transform="translate(0 0)"
                />
              </g>
            </svg>
          </div>
          <div class="acoordeon-content">
            <b-collapse id="collapse-2">
              <care-product />
            </b-collapse>
          </div>
        </div>
        <div class="acoordeon-body">
          <div v-b-toggle.collapse-3 class="acoordeon-header">
            <p class="title-header">Disponibilidad y envío</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16.026"
              height="9.425"
              viewBox="0 0 16.026 9.425"
            >
              <g
                id="Icon_ionic-ios-arrow-dropdown"
                data-name="Icon ionic-ios-arrow-dropdown"
                transform="translate(16.026 9.425) rotate(180)"
              >
                <path
                  id="Trazado_6696"
                  data-name="Trazado 6696"
                  d="M.4,9.023A1.362,1.362,0,0,1,.4,7.1L7.124.4A1.356,1.356,0,0,1,8.995.354l6.63,6.609a1.355,1.355,0,1,1-1.912,1.92l-5.7-5.611L2.315,9.03A1.356,1.356,0,0,1,.4,9.023Z"
                  transform="translate(0 0)"
                />
              </g>
            </svg>
          </div>
          <div class="acoordeon-content">
            <b-collapse id="collapse-3">
              <Shipping />
            </b-collapse>
          </div>
        </div>
      </div>
      <div
        v-if="imgsProduct.video"
        style="
          margin-left: -1rem;
          margin-right: -1rem;
          width: calc(100% + 2rem);
        "
        class="mb-3"
      >
        <video
          class="w-100"
          autoplay
          muted
          loop
          width="100%"
          style="background-color: #f0f5f6"
        >
          <source :src="imgsProduct.video" type="video/mp4" />
        </video>
      </div>
      <div
        v-for="index in imgsProduct.imagenes.length > 3
          ? 3
          : imgsProduct.imagenes.length - 1"
        :key="index"
        class="mb-3"
        style="
          margin-left: -1rem;
          margin-right: -1rem;
          width: calc(100% + 2rem);
        "
      >
        <img
          :src="imgsProduct.imagenes[index]"
          class="w-100"
          :alt="productDetail.titulo + ' index ' + index"
        />
      </div>
      <div v-if="imgProduct360 && imgProduct360.path_360" class="product-360">
        <vue-three-sixty-local
          :amount="imgProduct360.cantidad_360"
          button-class="light"
          spin-everse
          scroll-image
          :image-path="imgProduct360.path_360"
          :file-name="`{index}.jpg`"
        />
      </div>
      <rating-product />
      <div v-if="vitrinaRelacionados" class="position-relative col-12">
        <carousel-products
          :array-products="vitrinaRelacionados.productos"
          :id-vitrina="'vitrina-destacados-movil'"
          :slug-vitrina="'productos-destacados'"
          :name-vitrina="'vitrina de productos destacados'"
        />
      </div>
    </div>
    <div
      v-if="imgsProduct.habilitado"
      class="content-btn-add-product"
      :style="openSelectSize ? '' : 'z-index: 11;'"
    >
      <div
        v-if="openSelectSize && imgsProduct.tallas.length > 1"
        class="col-12 d-flex"
      >
        <button
          class="btn-primary-clover col-8 mx-auto"
          @click="openSelectSize = false"
        >
          Seleccionar talla
        </button>
      </div>
      <div v-else class="d-flex flex-wrap w-100">
        <div
          v-if="imgsProduct.tallas.length > 1"
          class="position-relative col-12 d-flex flex-wrap justify-content-center"
        >
          <div class="w-100 d-flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16.325"
              height="16.325"
              viewBox="0 0 16.325 16.325"
              class="ms-auto"
              @click="openSelectSize = true"
            >
              <path
                id="Trazado_4945"
                data-name="Trazado 4945"
                d="M0,0H16.325V16.325H0Z"
                fill="none"
              />
              <g id="Shopicon" transform="translate(2.24 2.24)">
                <path
                  id="Trazado_4946"
                  data-name="Trazado 4946"
                  d="M7.067,18.431l5.442-5.442,5.442,5.442.481-.481-5.442-5.442,5.442-5.442-.481-.481-5.442,5.442L7.067,6.586l-.481.481,5.442,5.442L6.586,17.95Z"
                  transform="translate(-6.586 -6.586)"
                />
              </g>
            </svg>
          </div>
          <p>Selecciona talla</p>
          <div
            v-if="imgsProduct.tallas.length > 1"
            class="box-size justify-content-center w-100 mb-4"
          >
            <div class="d-flex flex-wrap justify-content-center">
              <div
                v-for="(size, index) in imgsProduct.tallas"
                :key="index"
                :class="
                  size.stock
                    ? sizeSelect == size.id
                      ? 'size-select'
                      : ''
                    : 'size-disabled'
                "
                class="container-size"
                @click="size.stock > 0 ? $emit('changeSize', size.id) : ''"
              >
                {{ size.name }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="productDetail.referencias.length > 1"
          class="container-select-color-product col-3"
        >
          <div
            class="select-color-product justify-content-center"
            @click="clickColor = !clickColor"
          >
            <div class="d-flex align-items-center">
              <div
                class="color-select"
                :class="productDetail.referencias.length > 1 ? '' : 'me-0'"
                :style="
                  `background-color: ${imgsProduct.color_hx}; background-image: url(${imgsProduct.color_textura});`
                "
              />
            </div>
            <svg
              v-if="productDetail.referencias.length > 1"
              xmlns="http://www.w3.org/2000/svg"
              style="width: 16px; min-width: 16px; height: 9px"
              width="16.026"
              height="9.425"
              viewBox="0 0 16.026 9.425"
            >
              <g
                id="Icon_ionic-ios-arrow-dropdown"
                data-name="Icon ionic-ios-arrow-dropdown"
                transform="translate(16.026 9.425) rotate(180)"
              >
                <path
                  id="Trazado_6696"
                  data-name="Trazado 6696"
                  d="M.4,9.023A1.362,1.362,0,0,1,.4,7.1L7.124.4A1.356,1.356,0,0,1,8.995.354l6.63,6.609a1.355,1.355,0,1,1-1.912,1.92l-5.7-5.611L2.315,9.03A1.356,1.356,0,0,1,.4,9.023Z"
                  transform="translate(0)"
                />
              </g>
            </svg>
          </div>
          <div
            v-if="clickColor && productDetail.referencias.length > 1"
            class="container-options-select-color"
          >
            <div
              v-for="(color, index) in productDetail.referencias"
              :key="index"
              class="container-select-options"
              @click="color.habilitado ? changeColorInput(color) : ''"
            >
              <div
                class="color-select-option"
                :style="
                  `
                    background-color: ${color.color_hx};
                    background-image: url(${color.color_textura});
                    opacity: ${color.habilitado ? '1' : '.3'};
                  `
                "
              />
              <p
                class="mb-0 pe-2"
                :style="`opacity: ${color.habilitado ? '1' : '.3'};`"
              >
                {{
                  color.color_nombre
                    .toLowerCase()
                    .replace(/^\w/, d => d.toUpperCase())
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="col ms-3">
          <button
            class="btn-primary-clover w-100"
            :disabled="!productDetail.publicado"
            :title="productDetail.publicado ? '' : 'Producto agotado'"
            @click="sendSoliAddProduct()"
          >
            Agregar ${{
              new Intl.NumberFormat('de-DE').format(productDetail.precio)
            }}
          </button>
        </div>
      </div>
    </div>
    <size-product-detail />
  </div>
</template>
<script>
import BtnAddFav from '@/components/productDetail/productPage/BtnAddFav'
import VueThreeSixtyLocal from '@/components/productDetail/VueThreeSixty.vue'
import RatingProduct from '@/components/productDetail/RatingProduct.vue'
import CarouselProducts from '@/components/Templates/CarouselProducts'
import CareProduct from '@/components/productDetail/productPage/CareProduct'
import Shipping from '@/components/productDetail/productPage/Shipping'
import SizeProductDetail from '@/components/productDetail/productPage/SizeProductDetail.vue'
import ProductNotAvailable from '@/components/productDetail/productPage/ProductNotAvailable.vue'

import carousel from 'v-owl-carousel'
import { mapState, mapActions } from 'vuex'
import { sendFBQEvent } from '@/fbq'
export default {
  components: {
    carousel,
    BtnAddFav,
    VueThreeSixtyLocal,
    RatingProduct,
    CarouselProducts,
    CareProduct,
    Shipping,
    SizeProductDetail,
    ProductNotAvailable,
  },
  props: {
    colorSelect: {
      type: String,
      required: false,
      default: '',
    },
    sizeSelect: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      clickColor: false,
      openSelectSize: true,
      showMsgFewUnits: false,
      msgFewUnits: '',
    }
  },
  computed: {
    ...mapState('products', ['productDetail', 'vitrinaRelacionados']),
    ...mapState('cart', ['errorAddToCart', 'msgErrorAddToCart']),
    imgsProduct() {
      let imgsProductSelec = null
      if (this.productDetail && this.colorSelect) {
        imgsProductSelec = this.productDetail.referencias.find(
          e => e.color_id == this.colorSelect,
        )
      }
      return imgsProductSelec
    },
    imgProduct360() {
      if (
        this.productDetail &&
        this.productDetail.referencias.find(e => e.color_id == this.colorSelect)
      ) {
        return this.productDetail.referencias.find(
          e => e.color_id == this.colorSelect,
        )
      }
      return null
    },
  },
  watch: {
    colorSelect() {
      let colSelected = null
      colSelected = this.productDetail.referencias.find(
        e => e.color_id == this.colorSelect,
      )
      if (colSelected) {
        let sizeSelected = null
        sizeSelected = colSelected.tallas.find(e => e.id == this.sizeSelect)
        if (sizeSelected) {
          if (sizeSelected.alerta_stock == 1) {
            this.showMsgFewUnits = true
            if (sizeSelected.stock > 1) {
              this.msgFewUnits =
                '🔥 ¡Últimas ' + sizeSelected.stock + ' unidades disponibles!'
            } else {
              this.msgFewUnits = '🔥 ¡Última unidad disponible!'
            }
          } else {
            this.showMsgFewUnits = false
          }
        }
      }
    },
  },
  mounted() {
    document.getElementById('btn-whatsapp').style.bottom = '5.8rem'
  },
  methods: {
    ...mapActions('cart', ['addProductTocart']),
    async sendSoliAddProduct() {
      if (this.colorSelect && this.sizeSelect) {
        let data = {
          sku: this.productDetail.producto_sku,
          color: this.colorSelect,
          tallaId: this.sizeSelect,
        }
        await this.addProductTocart(data).then(() => {
          if (!this.errorAddToCart) {
            this.$bvModal.show('modal-add-to-cart')
            sendFBQEvent(this, 'AddToCart', {
              product_id: this.productDetail.producto_sku,
              value: this.productDetail.precio,
              content_type: 'product',
              content_name:
                this.productDetail.categoria_slug.toUpperCase() + ' CLOVER',
              content_category: this.productDetail.categoria_slug.toUpperCase(),
              content_ids:
                this.productDetail.producto_sku +
                this.colorSelect +
                this.imgsProduct.tallas[0].id,
              currency: 'COP',
            })
          } else {
            window.scrollTo(0, 0)
          }
        })
      }
    },
    changeColorInput(color) {
      this.$emit('changeColor', color.color_id)
      this.clickColor = !this.clickColor
    },
  },
}
</script>
<style lang="scss">
.container-product-detail-movil {
  display: none;
  .content-carousel-products {
    position: relative;
    margin-left: -1rem;
    margin-right: -1rem;
    width: calc(100% + 2rem);
    margin-bottom: 1rem;
    .owl-dots {
      position: absolute;
      bottom: 0.3rem;
      left: 50%;
      transform: translateX(-50%);
      .owl-dot {
        span {
          background-color: white;
          height: 5px;
          width: 5px;
          // box-shadow: 0 3px 6px #00000040;
        }
        &.active {
          span {
            background-color: #818181;
          }
        }
      }
    }
  }
  .container-data-product-movil {
    .container-price-product-detail {
      display: flex;
      font-size: 24px;
      margin-bottom: 20px;
      margin-bottom: 1.5rem;
      del {
        color: #818181;
        margin-right: 1rem;
      }
      p {
        margin-bottom: 0;
      }
    }
    .conatiner-acoordeon-movil {
      width: 100%;
      margin-bottom: 2rem;
      .acoordeon-body {
        &:first-child {
          border-top: 2px solid #f4f6f8;
        }
        border-bottom: 2px solid #f4f6f8;
        .acoordeon-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem;
          .title-header {
            margin-bottom: 0;
          }
        }
        .acoordeon-content {
          padding-right: 1rem;
          padding-left: 1rem;
        }
      }
    }
    .product-360 {
      margin-right: 0;
      margin-left: 0;
      width: 100%;
      position: relative;
      overflow: hidden;
      .v360-viewport {
        width: 200%;
        position: relative;
        left: -100%;
        transform: translateX(25%);
      }
    }
  }
  .content-btn-add-product {
    display: flex;
    flex-wrap: wrap;
    position: sticky;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    padding: 1rem;
    background-color: #ffffff;
    bottom: 0;
    z-index: 4;
  }
  @media (max-width: 768px) {
    display: block;
  }
}
.tags-product {
  display: flex;
  margin-bottom: 1rem;
  p {
    margin-bottom: 0;
    color: #818181;
    margin-right: 0.7rem;
    font-size: 13px;
    padding: 0.1rem 0.4rem;
    border-radius: 4px;
  }
}
</style>
